@mixin caret-down {
  border-top: $caret-width solid;
  border-right: $caret-width solid transparent;
  border-bottom: 0;
  border-left: $caret-width solid transparent;
}

@mixin caret-up {
  border-top: 0;
  border-right: $caret-width solid transparent;
  border-bottom: $caret-width solid;
  border-left: $caret-width solid transparent;
}

@mixin caret($direction: down) {
  @if $enable-caret {
    &::after {
      display: inline-block;
      width: 0;
      height: 0;
      margin-left: $caret-width * .85;
      vertical-align: $caret-width * .85;
      content: "";
      @if $direction == down {
        @include caret-down;
      } @else if $direction == up {
        @include caret-up;
      }
    }

    &:empty::after {
      margin-left: 0;
    }
  }
}
